var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "slide-image-and-description" } }, [
    _vm.displayImage && _vm.slideDescription
      ? _c("div", [
          _vm.layoutFormat === "image-left" ||
          _vm.layoutFormat === "image-left-large"
            ? _c("div", { class: "horz " + _vm.layoutFormat }, [
                _c("div", { staticClass: "image" }, [
                  _c("img", {
                    attrs: { src: _vm.imageUrl, alt: _vm.slide.image_alt_text },
                  }),
                ]),
                _c("div", { staticClass: "description" }, [
                  _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.slideDescription) },
                  }),
                ]),
              ])
            : _vm.layoutFormat === "image-right"
            ? _c("div", { class: "horz " + _vm.layoutFormat }, [
                _c("div", { staticClass: "description" }, [
                  _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.slideDescription) },
                  }),
                ]),
                _c("div", { staticClass: "image" }, [
                  _c("img", {
                    attrs: { src: _vm.imageUrl, alt: _vm.slide.image_alt_text },
                  }),
                ]),
              ])
            : _vm.layoutFormat === "image-top"
            ? _c("div", { class: "vert " + _vm.layoutFormat }, [
                _c("div", { staticClass: "image" }, [
                  _c("img", {
                    attrs: { src: _vm.imageUrl, alt: _vm.slide.image_alt_text },
                  }),
                ]),
                _c("div", { staticClass: "description" }, [
                  _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.slideDescription) },
                  }),
                ]),
              ])
            : _vm.layoutFormat === "image-bottom"
            ? _c("div", { class: "vert " + _vm.layoutFormat }, [
                _c("div", { staticClass: "description" }, [
                  _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.slideDescription) },
                  }),
                ]),
                _c("div", { staticClass: "image" }, [
                  _c("img", {
                    attrs: { src: _vm.imageUrl, alt: _vm.slide.image_alt_text },
                  }),
                ]),
              ])
            : _vm._e(),
        ])
      : _vm.slideDescription
      ? _c("div", [
          _c("div", { staticClass: "description" }, [
            _c("div", {
              domProps: { innerHTML: _vm._s(_vm.slideDescription) },
            }),
          ]),
        ])
      : _vm.displayImage
      ? _c("div", [
          _c("div", { staticClass: "image-vert" }, [
            _c("img", {
              attrs: { src: _vm.imageUrl, alt: _vm.slide.image_alt_text },
            }),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }